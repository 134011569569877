<template>
    <div>
      <h2>ПОД-10</h2>
      <el-form
        :model="pod10Form"
        ref="formedForm"
        label-position="top"
        label-width="120px"
      >
        <el-form-item label="Дата внесения учетной записи">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                v-model="reportForm.date"
                type="date"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-form-item>
  
        <el-form-item label="Период">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM"
                v-model="reportForm.startDate"
                type="month"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-form-item>
  
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item
              prop="organizationInfo"
              label="Объект / Филиал / Подразделение / Участок"
            >
              <el-select
              @change="changeOrg"
                style="width: 100%"
                clearable
                value-key="id"
                v-model="reportForm.organizationUnit"
                filterable
                placeholder="Выберите"
              >
                <el-option
                  v-for="item in organizationHierarchyByType.filter((obj,i) => i == 0 || role == 1)"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
  
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="Номер распорядительного документа">
              <el-select
                clearable
                class="searchPole"
                v-model="reportForm.administrativeDocument"
                filterable
                remote
                value-key="id"
                reserve-keyword
                placeholder="Номер документа"
                :remote-method="searchDocumentNumber"
              >
                <el-option
                  v-for="item in listDocumentNumbers"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
  
        <el-row>
          <el-col :span="4">
            <el-row>
            <el-form-item label="Тип файла">
              <el-select
                clearable
                v-model="reportForm.fileType"
                placeholder="Формат отчета"
              >
                <el-option
                  v-for="item in fileTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            </el-row>
            <el-row>
              <el-button @click="downloadReport('POD-10-2023')" type="primary" style="margin-left: 10px;"
              >Сформировать отчет</el-button>
            </el-row>
            <br>
            <el-row>
              <el-button @click="downloadReport('POD-10-2023-NOFIZ')" type="primary" style="margin-left: 10px;"
              >Сформировать отчет без графы 9 <br> "Поступило от физических лиц"</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import Cookies from "js-cookie";
  
  export default {
    name: "POD-10Report",
    data() {
      return {
        role: Cookies.get('role'),
        pod10Form:null,
        props: {
          id: "id",
          label: "name",
          children: "zones",
        },
        reportForm: {
          organizationUnit: {},
          responsiblePerson: {},
          inspector: {},
          administrativeDocument: {},
          startDate: null,
          endDate: null,
          date: null,
          fileType: null,
        },
  
        fileTypes: [
          {
            value: ".pdf",
            label: ".pdf",
          },
          {
            value: ".xls",
            label: ".xls",
          },
        ],
      };
    },
  
    computed: {
      ...mapGetters({
        organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
        listDocumentNumbers: "GETLISTDOCUMENTNUMBERS",
        listResponsiblePersonForSelect: "GETLISTRESPONSIBLEPERSONFORSEARCH",
      }),
    },
  
    methods: {
      changeOrg() {
        this.getListResponsiblePersonForSelect();
      }, 
  
      handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate);
      },
  
      loadNode(node, resolve) {
        if (node.level === 0) {
          return resolve([
            { id: 0, name: "Root1" },
            { id: 0, name: "Root2" },
          ]);
        }
        console.log(node);
        console.log(resolve);
      },
  
      downloadReport(NAME) {
        this.reportForm.responsiblePerson={id:1,name:"test"};
        this.reportForm.inspector={id:1,name:"test"};
        let date1 = new Date(this.reportForm.startDate);
        this.reportForm.startDate=date1;
        let date2 = new Date(this.reportForm.startDate);
        date2.setMonth(date1.getMonth()+1);
        this.reportForm.endDate=date2;
        let data = {
          fileName: "POD10-" + this.reportForm.date + this.reportForm.fileType,
          formData: this.reportForm,
        };
        this.$store.dispatch("downloadPod10Report", {data:data, reportName:NAME});
      },
  
      searchDocumentNumber(query) {
        this.$store.dispatch("getDocsNumbersSearch", { query: query });
      },
  
      getOrganizationHierarchyByType() {
        this.$store.dispatch("getOrganizationHierarchyByType", {
          type: 3,
        });
      },
  
      getListResponsiblePersonForSelect() {
         console.log(this.reportForm.organizationUnit);
        let units = [];
        if (this.reportForm.organizationUnit !== null) {
          units.push(this.reportForm.organizationUnit.id);
        }
        this.$store.dispatch("getListResponsiblePersonForSelect", units);
      },
    },
  
    mounted() {
      this.getOrganizationHierarchyByType();
      this.searchDocumentNumber("");
      this.getListResponsiblePersonForSelect(-1);
    },
  };
  </script>
  
  <style scoped></style>
  