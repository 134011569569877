<template>
    <p-o-d-10-2023-report></p-o-d-10-2023-report>
  </template>
  
  <script>
  import POD102023Report from "@/components/ecologist/reports/POD-10/POD-10Report-2023";
  export default {
    name: "POD-10-2023View",
    components: { POD102023Report },
  };
  </script>
  
  <style scoped></style>
  